<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path clip-rule="evenodd"
          d="M17.4857 12.1011C17.9663 11.6204 17.9663 10.8411 17.4857 10.3605C17.005 9.87984 16.2257 9.87984 15.7451 10.3605L8.36048 17.7451C8.12009 17.9855 7.99993 18.3006 8 18.6157C8 18.6158 8 18.616 8 18.6162C8 18.9695 8.14889 19.2881 8.38736 19.5125L15.7451 26.8703C16.2257 27.3509 17.005 27.3509 17.4857 26.8703C17.9663 26.3896 17.9663 25.6104 17.4857 25.1297L12.2029 19.847H30.1539C31.1236 19.847 32.0839 20.038 32.9798 20.4091C33.8758 20.7802 34.6898 21.3241 35.3756 22.0099C36.0613 22.6956 36.6052 23.5096 36.9763 24.4056C37.3475 25.3015 37.5385 26.2618 37.5385 27.2316C37.5385 29.1901 36.7604 31.0684 35.3756 32.4533C33.9907 33.8382 32.1124 34.6162 30.1538 34.6162H16.6154C15.9356 34.6162 15.3846 35.1672 15.3846 35.847C15.3846 36.5267 15.9356 37.0777 16.6154 37.0777H30.1538C32.7652 37.0777 35.2696 36.0404 37.1161 34.1939C38.9626 32.3473 40 29.8429 40 27.2316C40 25.9385 39.7453 24.6582 39.2505 23.4636C38.7557 22.269 38.0304 21.1836 37.1161 20.2693C36.2018 19.355 35.1164 18.6297 33.9218 18.1349C32.7272 17.6401 31.4469 17.3854 30.1539 17.3854H12.2013L17.4857 12.1011Z"
          fill="inherit"
          fill-rule="evenodd"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-undo-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
